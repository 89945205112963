/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import theme from '../../styles/theme';


const SearchWrapper = styled.div`
  .ais-InfiniteHits {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ais-InfiniteHits-list {
      max-width: 789px;
      width: 100%;
      list-style: none;
      margin: 50px 20px;

      .ais-InfiniteHits-item {
        margin-bottom: 36px;

        a.title {
          color: ${theme.textColor.blueText};

          &:hover {
            text-decoration: underline;
          }
        }
        .excerpt {
          display: block;
        }
        a.category {
          color: ${theme.textColor.suvaGray};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .ais-InfiniteHits-loadMore {
      background: none;
      border: none;
      color: ${theme.textColor.blueText};
      cursor: pointer;
      margin: 0 0 50px 0;

      &:hover {
        text-decoration: underline;
      }

      &.ais-InfiniteHits-loadMore--disabled {
        display: none;
      }
    }
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 50px 0;
  }
`;
SearchWrapper.displayName = 'SearchWrapper';

export default SearchWrapper;
