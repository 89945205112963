/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox, InfiniteHits
} from 'react-instantsearch-hooks-web';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import SearchItem from '../../components/search/searchItem';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HelpHero from '../../components/Help/HelpHero';
import Autocomplete from '../../components/Autocomplete';
import SearchStats from '../../components/search/searchStats';
import noResults from '../../components/search/noResults';
// Style
import { HeroHeading, BodyText } from '../../components/Global';
import SearchWrapper from '../../components/search/search.style';
// Utils
import searchHelper from '../../utils/searchHelper';
import generalHelper from '../../utils/general';


const getSubCategory = (subCategories, subCategoryId) => (
  subCategories.find(subCategory => subCategory.contentful_id === subCategoryId)
);

const SearchPage = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    location,
    indexName,
  } = props;

  /* USE STATE ------------------------------------------------*/
  const [searchClient, setSearchClient] = React.useState(null);

  /* LOCAL VARIABLES ------------------------------------------------*/
  const params = new URLSearchParams(location.search);
  const initialQuery = params.get('term');
  const staticData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            algolia {
              appId,
              apiKey
            }
          }
        }
        allContentfulHelpSubcateogry(sort: {fields: order, order: ASC}) {
          nodes {
            name
            contentful_id
            category {
              categoryTitle
              slug
            }
          }
        }
      }
    `
  );
  // Set Category objects:
  const subCategories = [...staticData.allContentfulHelpSubcateogry.nodes];


  /* USE EFFECT ------------------------------------------------*/
  useEffect(() => {
    const searchClientObject = algoliasearch(
      staticData.site.siteMetadata.algolia.appId,
      staticData.site.siteMetadata.algolia.apiKey
    );

    setSearchClient(searchClientObject);
  }, []);

  
  /* METHODS ------------------------------------------------*/
  const transformItems = items => items.map(item => ({
    ...item,
    subCategory: getSubCategory(subCategories, item.category),
  }));

  /* RENDER ------------------------------------------------*/
  if (!searchClient || generalHelper.isWindowUndefined()) {
    return null;
  }

  const instantSearchProps = {
    searchClient,
    indexName,
    initialUiState: {
      [indexName]: {
        query: initialQuery,
      },
    },
  };

  return (
    <SearchWrapper>
      <InstantSearch
        {...instantSearchProps}
      >
        <Layout location={location} useDarkBackground>
          <SEO
            title={`Results for "${initialQuery}" | Workast | Get Answers to FAQ's and Learn How to Make the Most of Workast`}
            description="Learn how to use all the functionalities and capabilities of Workast and get answers to frequently asked questions with our Help Desk."
          />
          <HelpHero>
            <HeroHeading mb="16px">Search</HeroHeading>
            <BodyText mb="29px">
              {initialQuery && (
                <SearchStats />
              )}
            </BodyText>
            {/* NOTE: This is required to make the initial search */}
            <SearchBox
              placeholder="Search for help on specific topics"
              style={{
                display: 'none',
              }}
            />
            <Autocomplete
              placeholder="Search for help on specific topics"
              initialState={{
                query: initialQuery
              }}
              onItemClick={searchHelper.onItemClick}
              onSelectItem={searchHelper.onSelectItem}
              onSubmit={searchHelper.onSubmit}
            />
          </HelpHero>
          <noResults.NoResultsBoundary
            fallback={<noResults.NoResults />}
          >
            <InfiniteHits
              hitComponent={SearchItem}
              showPrevious={false}
              transformItems={transformItems}
            />
          </noResults.NoResultsBoundary>
        </Layout>
      </InstantSearch>
    </SearchWrapper>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SearchPage.defaultProps = {
  indexName: 'help_center',
};
SearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      searchKey: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.arrayOf(PropTypes.any)
    })
  }).isRequired,
  indexName: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SearchPage;
