/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { useConnector } from 'react-instantsearch-hooks-web';
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats';

const useStats = props => useConnector(connectStats, props);

const SearchStats = (props) => {
  const {
    nbHits,
    query,
  } = useStats(props);
  let resultsWord;

  switch (nbHits) {
    case 1:
      resultsWord = 'result';
      break;
    default:
      resultsWord = 'results';
      break;
  }

  return <React.Fragment>{`${nbHits} ${resultsWord} for "${query}"`}</React.Fragment>;
};

export default SearchStats;
