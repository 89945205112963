/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Highlight,
} from 'react-instantsearch-hooks-web';
import { Link } from 'gatsby';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Utils
import urlHelper from '../../utils/url';


const SearchItem = (props) => {
  const { hit } = props;
  return (
    <article>
      <Link to={urlHelper.getHelpArticle(hit.slug)} className="title">
        <Highlight hit={hit} attribute="title" />
      </Link>
      <Highlight hit={hit} attribute="excerpt" className="excerpt" />
      {hit.subCategory && (
        <Link
          to={urlHelper.getHelpCategory(
            _get(hit, 'subCategory.category.slug', ''),
            urlHelper.convertToSlug(_get(hit, 'subCategory.name', ''))
          )}
          className="category"
        >
          {`${_get(hit, 'subCategory.category.categoryTitle', '')}/${_get(hit, 'subCategory.name', '')}`}
        </Link>
      )}
    </article>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SearchItem.propTypes = {
  hit: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    excerpt: PropTypes.string,
    subCategory: PropTypes.shape({
      name: PropTypes.string,
      contentful_id: PropTypes.string,
      category: PropTypes.shape({
        categoryTitle: PropTypes.string,
        slug: PropTypes.string,
      }),
    }),
  }).isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SearchItem;
