/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import {
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Img404 from '../../../static/images/404.png';
import { BodyText } from '../Global';


const NoResultsBoundary = ({ children, fallback }) => {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure to not display the No Results message
  // when no hits have been returned yet.
  // eslint-disable-next-line no-underscore-dangle
  if (results && !results.__isArtificial && results.nbHits === 0) {
    return (
      <React.Fragment>
        {fallback}
        <div hidden>{children}</div>
      </React.Fragment>
    );
  }

  return children;
};
NoResultsBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  fallback: PropTypes.element.isRequired,
};

const NoResults = () => {
  const { indexUiState } = useInstantSearch();

  return (
    <div className="no-results">
      <img src={Img404} alt="404 page" />
      <h3>
        {`No results for "${indexUiState.query}"`}
      </h3>
      <BodyText>Try adjusting your search</BodyText>
    </div>
  );
};

export default {
  NoResultsBoundary,
  NoResults,
};
